<template>
  <div class="container">
    <div class="container-home">
      <!-- <div class="container-nav">
        <div class="icon"><i class="fa-solid fa-bars"></i></div>
        <p>Đồng hành cùng sinh viên Bách khoa</p>
        <img class="image" src="@/assets/logodonghanh.png" alt="" />
      </div> -->
      <div class="container-post">
        <h4 class="title">Giới thiệu</h4>
        <img class="image-post" src="@/assets/Giasu.jpeg" alt="" />
        <p class="content">
          Theo thống kê, có trên 30% sinh viên Bách khoa đã từng hoặc có nguyện
          vọng làm gia sư các môn như Toán, Lý, Hóa, Tiếng Anh, Tin học, ...
          Nhằm giảm thiểu các chi phí về thời gian và tiền bạc cho các bên liên
          quan, Dự án An sinh phát triển một hệ thống CNTT hỗ trợ kết nối sinh
          viên dạy kèm phù hợp với nhu cầu gia sư của Phụ huynh.<br /><br />
          Chúng tôi mong nhận được sự phối hợp của các Sinh viên có muốn làm gia
          sư - dạy kèm, thông qua việc cung cấp thông tin phiếu gửi nguyện vọng
          dưới đây. Hệ thống sẽ tự động tìm kiếm, gửi danh sách các yêu cầu gia
          sư phù hợp với mong muốn và thông tin của các bạn cung cấp.
        </p>
        <p style="text-align: right">Nhóm iCTSV&EMGLABVN</p>
      </div>
      <div class="container-bill">
        <h3 class="title">Phiếu đăng ký làm gia sư</h3>
        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Thông tin của bạn
        </p>
        <p class="des-p">Họ và tên</p>
        <el-input
          size="small"
          placeholder="Nhập tên của bạn"
          v-model="nameTeach"
        >
        </el-input>
        <p class="des-p">Số điện thoại</p>
        <el-input size="small" placeholder="Nhập sđt" v-model="phone">
        </el-input>
        <p class="des-p">Email</p>
        <el-input size="small" placeholder="Nhập email" v-model="email">
        </el-input>
        <el-row :gutter="8">
          <el-col :lg="12" :md="12" :sm="24">
            <p class="des-p">Tỉnh/TP</p>
            <el-select
              v-model="cityName"
              placeholder="Chọn tỉnh/TP"
              size="small"
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in cityLst"
                :key="item.City"
                :value="item.City"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24">
            <p class="des-p">Quận/huyện</p>
            <el-select
              v-model="districtName"
              placeholder="Chọn quận/huyện"
              size="small"
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in districtLst"
                :key="item.District"
                :value="item.District"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24">
            <p class="des-p">Phường/xã</p>
            <el-select
              v-model="communeName"
              placeholder="Chọn phường/xã"
              size="small"
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in communeLst"
                :key="item.Commune"
                :value="item.Commune"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :lg="12" :md="12" :sm="24">
            <p class="des-p">Địa chỉ</p>
            <el-input
              size="small"
              placeholder="Nhập địa chỉ của bạn"
              v-model="addressTeach"
            >
            </el-input>
          </el-col>
        </el-row>

        <div style="margin-top: 8px">
          <el-radio v-model="isSelectSchool" label="Sinh viên Bách khoa HN"
            >Sinh viên Bách khoa HN</el-radio
          >
          <el-radio v-model="isSelectSchool" label="Khác">Khác</el-radio>
        </div>
        <el-input
          v-if="isSelectSchool == 'Sinh viên Bách khoa HN'"
          size="small"
          placeholder="Nhập MSSV"
          v-model="IDTeach"
          style="margin-top: 8px"
        >
        </el-input>
        <p class="des-p">Mục tiêu dạy kèm của bạn</p>
        <el-checkbox-group v-model="perposeTeach">
          <div v-for="item in listPerposeTeach" :key="item">
            <el-checkbox
              :label="item"
              border
              size="small"
              style="margin-bottom: 4px; max-width: 100%"
            ></el-checkbox>
          </div>
        </el-checkbox-group>
        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Khả năng và mong muốn của bạn
        </p>
        <div style="display: flex; justify-content: space-between">
          <p class="des-p">Nội dung có thể dạy kèm</p>
          <!-- <button class="bt-subject">Chọn</button> -->
        </div>
        <div style="display: flex">
          <el-select
            v-model="supportSubject"
            multiple
            placeholder="Kiến thức"
            size="small"
            style="height: 100%"
          >
            <el-option
              v-for="item in subjects"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="supportSkill"
            multiple
            placeholder="Kỹ năng"
            size="small"
            style="margin-left: 4px; height: 100%"
          >
            <el-option
              v-for="item in skills"
              :key="item.value"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <p class="des-p">Cấp học</p>
        <div style="display: flex">
          <el-select
            v-model="level"
            multiple
            placeholder="Cấp học"
            size="small"
            class="input-level"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <p class="des-p">Bạn có thể giúp người học</p>
        <el-checkbox-group v-model="support">
          <div v-for="item in listPerpose" :key="item">
            <el-checkbox
              :label="item"
              border
              size="small"
              style="margin-bottom: 4px; max-width: 100%"
            ></el-checkbox>
          </div>
        </el-checkbox-group>

        <p class="des-p">Thời gian dạy kèm</p>
        <el-select
          v-model="supportTime"
          multiple
          placeholder="Chọn thời gian"
          size="small"
          style="height: 100%; width: 100%"
        >
          <el-option
            v-for="item in times"
            :key="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <p class="des-p">Hình thức dạy</p>
        <el-radio v-model="formTeach" label="Online">Online</el-radio>
        <el-radio v-model="formTeach" label="Offline">Offline</el-radio>
        <el-radio v-model="formTeach" label="On & Off">Cả 2</el-radio>
        <p class="des-p">Địa điểm dạy</p>
        <el-select
          v-model="distanceRadius"
          placeholder="Chọn bán kính từ vị trí của bạn"
          size="small"
          style="height: 100%; width: 100%"
        >
          <el-option
            v-for="item in radius"
            :key="item.value"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <p
          class="des-p"
          style="color: #006161; font-weight: 500; margin-top: 20px"
        >
          Mong muốn về người học
        </p>
        <div style="display: flex">
          <p class="des-p">Người học:</p>
          <div style="display: flex; margin-top: 10px; margin-left: 10px">
            <el-radio v-model="sexTeacher" label="Nam">Nam</el-radio>
            <el-radio v-model="sexTeacher" label="Nữ">Nữ</el-radio>
            <el-radio v-model="sexTeacher" label="Cả 2">Cả 2</el-radio>
          </div>
        </div>

        <p class="des-p">Mong muốn thêm về người học</p>
        <el-input
          type="textarea"
          placeholder="Học phí, tính hình, kết quả học tập..."
          v-model="needMore"
        ></el-input>

        <div style="margin-top: 16px; text-align: center">
          <el-button
            type="primary"
            size="small"
            :loading="isClickBtSend"
            @click="btSendForm"
            >Gửi thông tin</el-button
          >
        </div>
      </div>
      <!-- <div class="container-footer">
        <div style="display: flex">
          <i class="fa-solid fa-file-lines" style="margin-top: 8px"></i>
          <p style="margin: 0; padding: 6px">
            Dự án kết nối sinh viên Bách khoa Hà Nội với phụ huynh
          </p>
        </div>
        <div style="display: flex">
          <i class="fa-solid fa-phone" style="margin-top: 8px"></i>
          <p style="margin: 0; padding: 6px">Liên hệ: 0397613784</p>
        </div>
        <div style="display: flex">
          <i class="fa-solid fa-location-dot" style="margin-top: 8px"></i>
          <p style="margin: 0; padding: 6px">
            Địa chỉ: Tòa nhà A17, Tạ Quang Bửu, Bách Khoa, Hai Bà Trưng, Hà Nội,
            Vietnam
          </p>
        </div>
      </div> -->
    </div>

    <div class="container-notify" v-if="isShowNotifySuccess">
      <div class="notify">
        <el-result
          icon="success"
          title="Đăng ký gia sư thành công"
          subTitle="Chúng tôi sẽ phản hồi vào email hoặc số điện thoại mà bạn đã cung cấp"
        >
          <template slot="extra">
            <el-button type="primary" size="small" @click="btConfirm"
              >Xác nhận</el-button
            >
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
import { GSCreateStudent } from "@/api/studentApi.js";
import { GetCity, GetDistrict, GetCommune } from "@/api/defaultApi.js";
export default {
  data() {
    return {
      sexTeacher: "Nam",
      isSelectSchool: "Sinh viên Bách khoa HN",
      support: [],
      level: "",
      options: [
        {
          value: "Tiểu học",
        },
        {
          value: "THCS",
        },
        {
          value: "THPT",
        },
        {
          value: "Đại học",
        },
        {
          value: "Khác",
        },
      ],
      subjects: [
        { value: "Toán" },
        { value: "Vật lý" },
        { value: "Hóa học" },
        { value: "Tiếng anh" },
        { value: "Ngữ văn" },
        { value: "Tư vấn tâm lý" },
        { value: "Các môn tiểu học" },
        { value: "Khác" },
      ],
      skills: [
        { value: "Tin học văn phòng" },
        { value: "Lập trình STEM" },
        { value: "Khác" },
      ],
      supportSubject: [],
      supportSkill: [],
      listPerpose: [
        "Khích lệ, giải tỏa áp lực học tập",
        "Hướng dẫn phương pháp học tập",
        "Xây dựng động lực học tập",
        "Cải thiện kết quả",
      ],
      infoMore: "",
      needMore: "",
      nameTeach: "",
      phone: "",
      email: "",
      addressTeach: "",
      IDTeach: "",
      isShowNotifySuccess: false,
      isClickBtSend: false,
      times: [
        { value: "Buổi tối" },
        { value: "Buổi chiều" },
        { value: "Buổi sáng" },
        { value: "Ngày cuối tuần" },
        { value: "Khác" },
      ],
      supportTime: [],
      perposeTeach: [],
      listPerposeTeach: [
        "Luyện tính kiên nhẫn, kỹ năng giao tiếp",
        "Cùng cố kiến thức kỹ năng gia sư",
        "Thêm kinh phí hỗ trợ học tập",
      ],
      formTeach: "On & Off",
      forms: [
        { value: "Online" },
        { value: "Offline" },
        { value: "Kết hợp ON & Off" },
      ],
      distanceRadius: "",
      radius: [
        { value: "Bán kính 2km" },
        { value: "Bán kính 3km" },
        { value: "Bán kính 4km" },
        { value: "Bán kính 5km" },
        { value: "> 5km" },
      ],
      cityLst: [],
      cityName: "",
      districtLst: [],
      districtName: "",
      communeLst: [],
      communeName: "",
    };
  },
  watch: {
    cityName() {
      this.getDistrict();
    },
    districtName() {
      this.getCommune();
    },
  },
  methods: {
    getCity() {
      GetCity({}).then((res) => {
        if (res.RespCode == 0) {
          this.cityLst = res.Data;
        }
      });
    },
    getDistrict() {
      if (!this.cityName) {
        this.$message({
          message: "Vui lòng chọn tỉnh/TP",
          type: "warning",
        });
        return;
      }
      GetDistrict({
        City: this.cityName,
      }).then((res) => {
        if (res.RespCode == 0) {
          this.districtLst = res.Data;
        }
      });
    },
    getCommune() {
      if (!this.cityName) {
        this.$message({
          message: "Vui lòng chọn tỉnh/TP",
          type: "warning",
        });
        return;
      }
      if (!this.districtName) {
        this.$message({
          message: "Vui lòng chọn quận/huyện",
          type: "warning",
        });
        return;
      }
      GetCommune({
        City: this.cityName,
        District: this.districtName,
      }).then((res) => {
        if (res.RespCode == 0) {
          this.communeLst = res.Data;
        }
      });
    },
    btSendForm() {
      if (this.supportSubject.length == 0) {
        this.$message({
          message: "Vui lòng chọn mục tiêu hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.support.length == 0) {
        this.$message({
          message: "Vui lòng chọn nội dung hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.level == "") {
        this.$message({
          message: "Vui lòng chọn cấp học",
          type: "warning",
        });
        return;
      }
      if (this.nameTeach == "") {
        this.$message({
          message: "Vui lòng điền họ tên phụ huynh",
          type: "warning",
        });
        return;
      }
      if (this.phone == "") {
        this.$message({
          message: "Vui lòng điền số điện thoại liên lạc",
          type: "warning",
        });
        return;
      }
      if (this.email == "") {
        this.$message({
          message: "Vui lòng điền email liên lạc",
          type: "warning",
        });
        return;
      }
      if (this.addressTeach == "") {
        this.$message({
          message: "Vui lòng điền địa chỉ của bạn",
          type: "warning",
        });
        return;
      }
      if (this.isSelectSchool == "Sinh viên Bách khoa HN") {
        if (this.IDTeach.length == 0) {
          this.$message({
            message: "Vui lòng nhập MSSV của bạn",
            type: "warning",
          });
          return;
        }
        if (this.IDTeach.length < 8 || this.IDTeach.length > 9) {
          this.$message({
            message: "Vui lòng nhập đúng MSSV",
            type: "warning",
          });
          return;
        }
      }
      this.isClickBtSend = true;
      var req = {
        StudentID: this.IDTeach,
        Address: this.addressTeach,
        StudentName: this.nameTeach,
        Email: this.email,
        City: this.cityName,
        District: this.districtName,
        Ward: this.communeName,
        Phone: this.phone,
        Radius: this.distanceRadius,
        FormTeach: this.formTeach,
        InfoMore: this.infoMore,
        Level: this.level.join("; "),
        Need: this.needMore,
        SexStudent: this.sexTeacher,
        SelectSchool: this.isSelectSchool,
        NameSupports: this.support.join("; "),
        Subjects: this.supportSubject.join("; "),
        SkillSupport: this.supportSkill.join("; "),
        TimeSupport: this.supportTime.join("; "),
        Perpose: this.perposeTeach.join("; "),
      };
      GSCreateStudent({
        Data: req,
      }).then((res) => {
        if (res.RespCode == 0) {
          this.isClickBtSend = false;
          this.isShowNotifySuccess = true;
        }
      });
      // infoTeacher
      //   .add({
      //     level: this.level,
      //     supportSubject: this.supportSubject,
      //     supprtSkill: this.supportSkill,
      //     support: this.support,
      //     infoMore: this.infoMore,
      //     sexTeacher: this.sexTeacher,
      //     selectedSchool: this.isSelectSchool,
      //     needMore: this.needMore,
      //     nameTeach: this.nameTeach,
      //     phoneEmail: this.phoneEmail,
      //     addressTeach: this.addressTeach,
      //     IDTeach: this.IDTeach,
      //     perposeTeach: this.perposeTeach,
      //     supportTime: this.supportTime,
      //     formTeach: this.formTeach,
      //     distanceRadius: this.distanceRadius,
      //   })
      //   .then(() => {
      //     this.isClickBtSend = false;
      //     this.isShowNotifySuccess = true;
      //   })
      //   .catch(() => {
      //     this.$message({
      //       message: "Vui lòng kiểm tra lại đường chuyền mạng",
      //       type: "error",
      //     });
      //   });
    },
    btConfirm() {
      this.isShowNotifySuccess = false;
    },
  },
  created() {
    this.getCity();
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #0094947a;
  // max-width: 600px;
  // margin: 0 auto;
  position: relative;
  .container-home {
    padding-top: 1px;
    padding-bottom: 8px;
    .container-post {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .title {
        margin: 0;
        margin-bottom: 16px;
        text-align: center;
        color: #006161;
      }
      .image-post {
        width: 100%;
        border-radius: 8px;
      }
      .content {
        margin: 0;
        margin-top: 8px;
      }
    }
    .container-bill {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .input-level {
        margin-right: 4px;
        width: 100%;
      }
      .input-class {
        margin-left: 4px;
      }
      .title {
        margin: 0 8px 0 0;
        text-align: center;
        color: #006161;
      }
      .des-p {
        margin: 8px 0px;
        font-size: 14px;
      }
      .bt-subject {
        border: none;
        background: none;
        color: #009394;
      }
      .el-checkbox {
        margin-right: 0px;
      }
    }
    .container-footer {
      background: #009394;
      color: #fff;
      box-shadow: 1px 0 5px rgba(0, 0, 0, 0.15);
      padding: 8px 20px;
      p {
        font-size: 14px;
      }
    }
  }
  .container-notify {
    position: fixed;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-align: center;
    transition: all 0.5s ease-in;
    .notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgb(245, 255, 223);
      width: 360px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
<style>
.container-bill .el-input__inner {
  font-size: 14px !important;
}
.container-bill .el-textarea__inner {
  font-size: 14px !important;
  resize: none;
}
</style>
