import request from '@/utils/request.js'
// Sinh viên
export function CheckStudentApply(data) {
  return request({
    url: 'GSStudent/CheckStudentApply',
    method: 'post',
    data: data
  })
}

export function StudentApply(data) {
  return request({
    url: 'GSStudent/StudentApply',
    method: 'post',
    data: data
  })
}

export function GetStudentLstByParentID(data) {
  return request({
    url: 'GSStudent/GetStudentLstByParentID',
    method: 'post',
    data: data
  })
}

export function GSCreateStudent(data) {
  return request({
    url: 'GSStudent/GSCreateStudent',
    method: 'post',
    data: data
  })
}